import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import settings from '../../../kenra-storybook/global/settings'

const TallOuterContainer = styled.div.attrs(({ dynamicHeight }) => ({
    style: { height: `${dynamicHeight}px` }
}))`
  position: relative;
  width: 100%;
  margin-bottom: -450px;
  @media (min-width: ${settings.bp.small.view}) {
    margin-bottom: -250px;
  }
}
`

const StickyInnerContainer = styled.div`
  position: sticky;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  margin-bottom: 80px;
  top: 80px;
  @media (min-width: ${settings.bp.medium.view}) {
    margin-bottom: 85px;
    top: 0;
}

`

const HorizontalTranslateContainer = styled.div.attrs(({ translateX }) => ({
    style: { transform: `translateX(${translateX}px)` }
}))`
  position: absolute;
  height: 100%;
  will-change: transform;
  @media (min-width: 1650px) {
    width: 100%;
  }
`

const calcDynamicHeight = objectWidth => {
    const vw = window.innerWidth
    const vh = window.innerHeight
    return objectWidth - vw + vh + 150
}

const handleDynamicHeight = (ref, setDynamicHeight) => {
    const objectWidth = ref?.current?.scrollWidth
    if (objectWidth) {
        const dynamicHeight = calcDynamicHeight(objectWidth)
        setDynamicHeight(dynamicHeight)
    }
}

const applyScrollListener = (ref, setTranslateX) => {
    window.addEventListener("scroll", () => {
        const offsetTop = -ref?.current?.offsetTop
        if (offsetTop) {
            setTranslateX(offsetTop)
        }
    })
}

export default ({ children }) => {
    const [dynamicHeight, setDynamicHeight] = useState(null)
    const [translateX, setTranslateX] = useState(0)

    const containerRef = useRef(null)
    const objectRef = useRef(null)

    const resizeHandler = () => {
        handleDynamicHeight(objectRef, setDynamicHeight)
    }

    useEffect(() => {
        handleDynamicHeight(objectRef, setDynamicHeight)
        window.addEventListener("resize", resizeHandler)
        applyScrollListener(containerRef, setTranslateX)
    }, [])

    return (
        <TallOuterContainer dynamicHeight={dynamicHeight}>
            <StickyInnerContainer ref={containerRef}>
                {/* <div style={{ position: 'absolute', top: '80px', left: '200px', zIndex: 9999 }}>title appears here</div> */}
                <HorizontalTranslateContainer translateX={translateX} ref={objectRef}>
                    {children}
                </HorizontalTranslateContainer>
            </StickyInnerContainer>
        </TallOuterContainer>
    )
}
