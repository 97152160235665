import React, { useState, useEffect, Fragment } from 'react'
import styled, { css } from 'styled-components'
import { SharedStyles } from "../../../kenra-storybook/index"
import settings from '../../../kenra-storybook/global/settings'
import { useSwipeable } from 'react-swipeable'
const { StLinkMore } = SharedStyles
import { blue, ItemImageWrapper, MobileImage, Image, ProductInfoText, ProductInfoTitle } from './UltraAshLandingPage'
import CustomLink from 'components/Link'

const StProductCarouselContainer = styled.div`
    margin: auto;
    display: flex;
    flex-direction: column;

    @media (min-width: ${settings.bp.small.view}) {
        display: ${props => (props.showDesktop ? 'flex' : 'none')};
    };
    }
`

const StTestimonalMobileContentCards = styled.div`
display: flex;
flex-direction: row;
width: 100%;
align-self: center;
overflow: hidden;
`


const StDots = styled.div`
display: block;
`

const MobileAlternativeProduct = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px;
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.08);
    border-radius: 16px 16px 16px 16px;
    min-height: 390px;
    ${props =>
        props.shorter &&
        css`
        min-height: 390px;
        `};
`




export const ProductCarousel = props => {
    const { products, backgroundColor, shorter, showDesktop } = props
    const maxIndex = products.length - 1
    const [index, setIndex] = useState(0)
    const [autoRotate, setAutoRotate] = useState(false)
    const [mousePosition, setMousePosition] = useState(0)

    const mouseMoveHandler = event => {
        const { width } = event.target.getBoundingClientRect()
        const percentage =
            ((event.clientX - event.target.offsetLeft) / width) * 100
        setMousePosition(percentage)
    }

    useEffect(() => {
        window.addEventListener('mousemove', mouseMoveHandler)
        return () => {
            window.removeEventListener('mousemove', mouseMoveHandler)
        }
    }, [])

    const config = {
        delta: 10,
        preventScrollOnSwipe: false,
        trackTouch: true,
        trackMouse: false,
        rotationAngle: 0, // set a rotation angle
        swipeDuration: 250, // allowable duration of a swipe (ms). *See Notes*
        touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
    }


    const swipeHandler = useSwipeable({
        onSwiped: eventData => {
            if (autoRotate) {
                setAutoRotate(false)
            }
            if (eventData.dir === 'Left' && eventData.deltaX < -50) {
                let newIndex = index + 1 > maxIndex ? 0 : index + 1
                setIndex(newIndex)
            } else if (eventData.dir === 'Right' && eventData.deltaX > 50) {
                let newIndex = index - 1 < 0 ? maxIndex : index - 1
                setIndex(newIndex)
            }
        },
        ...config,
    })




    useEffect(() => {
        if (autoRotate) {
            const interval = setInterval(() => {
                let newIndex = index + 1 > maxIndex ? 0 : index + 1
                setIndex(newIndex)
            }, 8000)
            return () => clearInterval(interval)
        }
    }, [index, autoRotate])

    if (!products) {
        return null
    }


    return (
        <StProductCarouselContainer
            showDesktop={showDesktop}
            backgroundColor={backgroundColor}
            onClick={() => {
                if (autoRotate) {
                    setAutoRotate(false)
                }
                if (mousePosition >= 50) {
                    let newIndex = index + 1 > maxIndex ? 0 : index + 1
                    setIndex(newIndex)
                } else {
                    let newIndex = index - 1 < 0 ? maxIndex : index - 1
                    setIndex(newIndex)
                }
            }}
            {...swipeHandler}
        >
            <div
                style={{
                    flex: 1,
                    marginBottom: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                key={`testimonial_${index}`}
            >
                <StTestimonalMobileContentCards>
                    {products.map((p, pindex) => {
                        return [index].includes(pindex) ? (
                            <MobileAlternativeProduct key={pindex} shorter={shorter}>
                                <ItemImageWrapper>
                                    <Image src={p.text2} />
                                </ItemImageWrapper>
                                <div style={{ paddingTop: 40 }}>
                                    <ProductInfoTitle wide>{p.title}</ProductInfoTitle>
                                </div>
                                <StLinkMore rounded invertTextColor center topMargin bottomMargin morePadding hoverColor={blue} responsiveFont>
                                    <CustomLink target={"_blank"} style={{ 'letterSpacing': '0.659px' }} href={`${p.ctaLink}`}>
                                        {p.ctaText}
                                    </CustomLink>
                                </StLinkMore>
                            </MobileAlternativeProduct>) : null
                    }
                    )}
                </StTestimonalMobileContentCards>
            </div>
            <StDots style={{ textAlign: 'center' }}>
                {products.map((p, pIndex) => {
                    return (
                        <span
                            key={`dot-${pIndex}`}
                            style={
                                [index].includes(pIndex)
                                    ? {
                                        backgroundColor: '#000',
                                        height: '7px',
                                        width: '7px',
                                        borderRadius: '50%',
                                        display: 'inline-block',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                    }
                                    : {
                                        height: '7px',
                                        width: '7px',
                                        backgroundColor: '#D9D9D9',
                                        borderRadius: '50%',
                                        display: 'inline-block',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                    }
                            }
                        />
                    )
                })}
            </StDots>
        </StProductCarouselContainer>
    )
}
