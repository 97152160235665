import React, { Fragment } from "react"
import get from 'lodash.get'
import { injectIntl } from "gatsby-plugin-react-intl"
import HeroSlider from "components/HeroSlider"
import {
    Spacing,
    SharedStyles,
    Container,
} from "../../../kenra-storybook/index"
import CustomLink from 'components/Link'
const { StPageTitle, StLinkMore } = SharedStyles
import styled, { css } from "styled-components"
import settings from "../../../kenra-storybook/global/settings"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { ProductCarousel } from "./ProductCarousel"
import ScrollingProductsRow from './ScrollingProductsRow'

const MobileOnly = styled.div`
    display: block;

    @media (min-width: ${settings.bp.small.view}) {
        display: none;
    }
`

const DesktopOnly = styled.div`
    display: none;

    @media (min-width: ${settings.bp.small.view}) {
        display: block;
    }
`


export const ItemImageWrapper = styled.div.attrs(props => ({
    className: 'ItemImageWrapper',
}))`
    color: #121212;
    padding-left: 9px;
    padding-right: 9px;
    position: relative;
    min-height: 134px;
    @media (min-width: ${settings.bp.medium.view}) {
        padding-left: 0px;
        padding-right: 0px;
        max-width: 1200px;
        margin: auto;
    }

    object-fit: contain;
    & img:nth-child(1) {
        // display: block;
    }
    & img:nth-child(2) {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        left: 0;
        top: 0;
    }
    @media (min-width: ${settings.bp.medium.view}) {
        &:hover {
            & img:nth-child(1) {
                // display: none;
            }
            & img:nth-child(2) {
                display: block;
            }
        }
    }
`

export const BenefitItemImageWrapper = styled.div.attrs(props => ({
    className: 'ItemImageWrapper',
}))`
    display: block;
    text-align: center;
    color: #121212;
    padding-left: 9px;
    padding-right: 9px;
    position: relative;
    min-height: 84px;
    @media (min-width: ${settings.bp.medium.view}) {
        display: flex;
        padding-left: 0px;
        padding-right: 0px;
        max-width: 740px;
        margin: auto;
        text-align: left;

    }
    @media (min-width: ${settings.bp.small.view}) {
    }

`


const Icon = styled.img`
    width: 100%;
    height: 100%;
    display: block;
    margin: auto;
    @media (min-width: ${settings.bp.medium.view}) {
        margin: 0;
    }
`

const DesktopImage = styled.img`
    width: 100%;
    height: 100%;
    display: block;

    @media (max-width: ${settings.bp.small.view}) {
        display: none;
    }
`

export const MobileImage = styled.img`
    width: 100%;
    height: 100%;
    display: none;
    @media (max-width: ${settings.bp.medium.view}) {
        display: block;
    }
`

const DesktopBenefitImage = styled.img`
    width: 100%;
    height: 100%;
    display: block;

    @media (max-width: ${settings.bp.midSmall.view}) {
        display: none;
    }
`

export const MobileBenefitImage = styled.img`
    display: none;
    @media (max-width: ${settings.bp.midSmall.view}) {
        display: block;
        position: absolute;
        padding: 25px;
        min-height: 600px;
        object-fit: cover;
        border-radius: 40px;
    }
`


export const Image = styled.img`
    width: 100%;
    height: 100%;
    display: block;
`



const DesktopProductRow = styled.div`
    display: none;
    @media (min-width: ${settings.bp.small.view}) {
        display: flex;
        flex-direction: row;
    }
`


const ProductImageContainer = styled.div`
  width: 100%;
  margin: 10px;
  @media (min-width: ${settings.bp.medium.view}) {
    width: 50%;
}
`

const BenefitContainer = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 40px;
  padding: 25px;
  @media (min-width: ${settings.bp.midSmall.view}) {
    margin-top: 0px;
    width: 100%;
    padding: 20px;
  }
  @media (min-width: ${settings.bp.small.view}) {
    width: 100%;
    padding: 20px;
  }
`

const BenefitContainerDesktop = styled.div`
    padding: 10px;
    display: none;
    @media (min-width: ${settings.bp.midSmall.view}) {
        width: 100%;
        margin: auto;
        padding: 0px;
        display: block;
    }
`

const ProductInfoContainer = styled.div`
    margin: 10px;
    width: 100%;
    align-content: center;
    border-radius: 16px;
    background: #FFF;
    color: #121212;
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.08);
    @media (min-width: ${settings.bp.medium.view}) {
        width: 50%;
    }
`

export const ProductInfoTitle = styled.div`
    font-size: 24px;
    line-height: 115%;
    letter-spacing: 2px;
    font-weight: 500;
    text-align: center;
    padding-left: 100px;
    padding-right: 100px;
    color: #121212;
    margin: 5px;
    ${props =>
        props.wide &&
        css`
        padding-left: 6px;
        padding-right: 6px;
        `};
`
export const ProductInfoText = styled.div`
    font-size: 16px;
    line-height: 115%;
    letter-spacing: 2px;
    font-weight: 500;
    opacity: 75%;
    text-align: center;
    padding-left: 64px;
    color: #121212;
    padding-right: 64px;
    margin-bottom: 10px;
    ${props =>
        props.wide &&
        css`
        padding-left: 6px;
        padding-right: 6px;
        `};
`

const VideoImage = styled.div`
    margin-left: 20px;
    margin-right: 20px;
    @media (min-width: ${settings.bp.medium.view}) {
        max-width: 1200px;
        margin: auto;
    }
`

const GreySection = styled.div`
    background-color: #0000000A;
    padding: 32px;
    @media (max-width: ${settings.bp.midSmall.view}) {
       background-color: unset;
       padding: 0px;
    }
`

const PageTitle = styled.div`
    max-width: 740px;
    margin: auto;
    text-align: center;
    @media (max-width: ${settings.bp.small.view}) {
        margin-left: 20px;
    margin-right: 20px;
     }
    
`

const BeforeAfterTitleContainer = styled.div`
    text-align: center;
    margin-top: 22px;
    margin-bottom: 22px;
    @media (max-width: ${settings.bp.small.view}) {
        margin-bottom: 12px;
        margin-top: 140px;
    }
    ${props => props.left && css`
    @media (max-width: ${settings.bp.small.view}) {
      margin-top: 20px;
     }
    `}
`


const BeforeAfterImages = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 22px;
    @media (max-width: ${settings.bp.small.view}) {
        flex-direction: column;
    }
`

const BeforeAfterImage = styled.div`
    padding-right: 5px;
    padding-left: 5px;
    @media (max-width: ${settings.bp.small.view}) {
        padding-right: 25px;
        padding-left: 25px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
`

const BAContainer = styled.div`
    margin-left: 25px;
    margin-right: 25px;
    text-align: center;
    @media (max-width: ${settings.bp.small.view}) {
        margin-bottom: 24px;
    }
    ${props => props.left && css`
    margin-left: 16px;
    margin-right: 16px;
    @media (max-width: ${settings.bp.small.view}) {
        margin-left: 45px;
        margin-right: 45px;
    }
    
    `}
`

const BAContainer2 = styled.div`
margin: auto;
padding-top: 1px;
max-width: 740px;
`



const BASubContainer = styled.div`
    margin-left: 25px;
    margin-right: 25px;
    text-align: center;
    @media (max-width: ${settings.bp.small.view}) {
        margin-bottom: 24px;
    }
    ${props => props.left && css`
    text-align: left;
     display: flex; 
     flex-direction: column;
     margin-left: 0px;
     margin-right: 0px;
     margin-bottom: 20px;
     `}
`

const BABoldSubtitle = styled.span`
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 115%;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #121212;
    @media (max-width: ${settings.bp.small.view}) {
        font-size: 16px;
    }
`

const BASubtitle = styled.span`
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
    letter-spacing: 2px;
    color: #121212;
    margin-left: 10px;
    @media (max-width: ${settings.bp.small.view}) {
        font-size: 16px;
    }
    ${props => props.left && css`
    margin-left: 0px;
    `}
`

const Title = styled.span`
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #121212;
    @media (max-width: ${settings.bp.small.view}) {
        font-size: 20px;
     }
`

const SubTitle = styled.span`
    font-size: 24px;
    line-height: 115%;
    letter-spacing: 2px;
    text-transform: unset;
    color: #121212;
    @media (max-width: ${settings.bp.small.view}) {
        font-size: 16px;
    }
`

const TitleSpan = styled.span`
    font-size: 25px!important;
    font-style: normal;
    font-weight: 700!important;;
    line-height: 115%;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #121212;
    @media (max-width: ${settings.bp.small.view}) {
        font-size: 20px!important;;
    }
`

const ProductTitleSpan = styled.span`
    font-size: 25px!important;;
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #121212;
    @media (max-width: ${settings.bp.small.view}) {
        font-size: 20px!important;
    }
`
const StyledParagraph = styled.p`
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #121212;
    text-align: center;
    position: relative;
    @media (max-width: ${settings.bp.small.view}) {
        font-size: 20px;
    }
`

export const blue = '#B5D9F2'


const parseSection1Data = section1Data => {
    return section1Data?.fragments?.map(section => {
        let returnData = {}
        section.forEach(fragmentData => {
            switch (fragmentData.key) {
                case "Image":
                    returnData["img"] = fragmentData.value
                    break
                case "Title":
                    returnData["text"] = fragmentData.value
                    break
                case "Text":
                    returnData["link"] = fragmentData.value
                    break
                case "Subtitle":
                    returnData["url"] = fragmentData.value
                    break
                default:
                    break
            }
        })

        return {
            image: returnData.img,
            text: returnData.text,
            link: returnData.link?.toUpperCase(),
            url: returnData.url,
        }
    })
}

const parseCollectionData = (collectionData, isBa) => {
    let fragmentDataMap = collectionData?.fragments?.map((section, index) => {
        let returnData = {}
        section.forEach((fragmentData) => {
            switch (fragmentData.key) {
                case 'Image':
                    returnData['img'] = fragmentData.value
                    break
                case 'Overlay':
                    returnData['overlay'] = fragmentData.value
                    break
                case 'Title':
                    returnData['title'] = fragmentData.value
                    break
                case 'Text':
                    returnData['text'] = fragmentData.value
                    break
                case 'ItemColor':
                    returnData['color'] = fragmentData.value
                    break
                case 'ProductLink':
                    returnData['productLink'] = fragmentData.value
                    break
                case 'HighlightText':
                    returnData['highlightText'] = fragmentData.value
                    break
                default:
                    break
            }
        })

        let text1heading
        let text2heading
        let text1content
        let text2content
        if (index === 1) {
            const text1 = returnData.text?.length > 0 && returnData.text?.split(':')
            const text2 = returnData.highlightText?.length > 0 && returnData.highlightText?.split(':')
            text1heading = text1?.length > 1 && text1?.[0]?.concat(':')
            text2heading = text2?.length > 1 && text2?.[0]?.concat(':')
            text1content = text1?.[1]
            text2content = text2?.[1]
        }

        return isBa && index === 0 ? {
            beforeImage: returnData.img,
            afterImage: returnData.overlay,
            title: returnData.title,
            text: returnData.text,
            subtitle: returnData.highlightText,
            subtitleBold: returnData.color,
        } : isBa && index === 1 ? {
            beforeImage: returnData.img,
            afterImage: returnData.overlay,
            title: returnData.title,
            text1heading,
            text2heading,
            text1content,
            text2content,
            ctaText: returnData.color,
            ctaLink: returnData.productLink,
        } : {
            image: returnData.img,
            hoverImage: returnData.overlay,
            title: returnData.title,
            text: returnData.text,
            text2: returnData.highlightText,
            ctaText: returnData.color,
            ctaLink: returnData.productLink,
        }
    })

    return fragmentDataMap
}

const parseCarouselSlides = slides => {
    return slides?.map(s => {
        return {
            image: s.image?.localFile?.publicURL,
            text: s.slideText,
        }
    })
}


const UALanding = ({
    intl,
    page,
    allShopifyProducts,
    allGoogleSheetProductsRow, }) => {
    const {
        banner, // hero banner
        title, // page title
        text: subTitleText, // subheading text
        section1Title: newTitle, // shade chart block title bold
        section1Subtitle: newProductTitle, // shade chart block subtitle normal
        section1Data, // shade chart block image
        carouselTitle: benefitsTitle, //benefits section title
        collection1Benefits: benefitsMainImage, // benefits section main image
        carouselSlides, // benefits section imagery
        moreCollectionData, // before and after data
        educationVideoTitle, // video title
        educationVideoImage, // image for video placeholder
        educationVideos, // the video file to play
        sustainabilityTitle: videoCtaText, // video cta text
        section2Text: videoYoutubeUrl, // youtube url to video
        collectionTitleColor: ultraAshProductsTitle, // other products title
        collectionData, // the UA products
        stylistFavoritesTitle: shopTitle, // shop title
        stylistFavoritesProducts: rawShopProducts, // shop products
    } = page

    const chartData = parseSection1Data(section1Data)
    const shadeChartImage = chartData?.[0]?.image
    const ultraAshProducts = parseCollectionData(collectionData, false)
    const beforeAndAfterData = parseCollectionData(moreCollectionData, true)
    const benefitsImagery = parseCarouselSlides(carouselSlides)

    const shopProducts = rawShopProducts.map(p => {
        return {
            image: p.productItem?.images?.[0]?.localFile?.publicURL,
            title: p.title,
            text: p.text,
            text2: p.productItem?.images?.[0]?.localFile?.publicURL,
            ctaText: "View Product",
            ctaLink: p.shopifyProduct?.handle,
        }
    })


    return (
        <Fragment>
            {banner && (
                <Spacing removeSpaceTop>
                    <HeroSlider slides={banner} />
                </Spacing>
            )}

            {title && (
                <Spacing removeSpaceBottom>
                    <PageTitle>
                        <Title>{title}</Title>
                        <br />
                        <SubTitle style={{ opacity: '65%' }}>
                            {documentToReactComponents(
                                JSON.parse(subTitleText.raw || '{}')
                            )}
                        </SubTitle>
                    </PageTitle>
                </Spacing>
            )}

            {shadeChartImage && (
                <Spacing style={{ maxWidth: '740px', margin: 'auto' }}>
                    <Container>
                        <StPageTitle style={{ marginBottom: 0 }}>
                            <TitleSpan>{newTitle}</TitleSpan>{' '}
                            <ProductTitleSpan>
                                {newProductTitle}
                            </ProductTitleSpan>
                        </StPageTitle>
                        <ItemImageWrapper>
                            <Image src={shadeChartImage} />
                        </ItemImageWrapper>
                    </Container>
                </Spacing>
            )}

            {benefitsTitle && benefitsImagery && (
                <Spacing>
                    <GreySection>
                        <div
                            style={{
                                maxWidth: '740px',
                                margin: 'auto',
                                display: 'flex',
                            }}
                        >
                            <MobileBenefitImage
                                src={
                                    benefitsMainImage?.[0]?.mobileImage?.localFile
                                        ?.publicURL
                                }
                            />
                            <BenefitContainerDesktop>
                                <ItemImageWrapper>
                                    <DesktopBenefitImage
                                        src={
                                            benefitsMainImage?.[0]?.image
                                                ?.localFile?.publicURL
                                        }
                                    />
                                    <Image
                                        src={
                                            benefitsMainImage?.[0]?.smallImage
                                                ?.localFile?.publicURL
                                        }
                                    />
                                </ItemImageWrapper>
                            </BenefitContainerDesktop>
                            <BenefitContainer>
                                <div>
                                    <StyledParagraph>
                                        {benefitsTitle}
                                    </StyledParagraph>
                                </div>

                                {benefitsImagery.map((slide, index) => {
                                    return (
                                        <BenefitItemImageWrapper key={index}>
                                            <Icon
                                                style={{ maxWidth: '64px' }}
                                                src={slide.image}
                                            />
                                            <p
                                                style={{
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '500',
                                                    lineHeight: '115%',
                                                    letterSpacing: '2px',
                                                    color: '#121212',
                                                    paddingLeft: 16,
                                                }}
                                            >
                                                {slide.text}
                                            </p>
                                        </BenefitItemImageWrapper>
                                    )
                                })}
                            </BenefitContainer>
                        </div>
                    </GreySection>
                </Spacing>
            )}

            {/* {beforeAndAfterData?.length > 0 && (
                <Spacing style={{ maxWidth: '740px', margin: 'auto' }}>
                    <BeforeAfterTitleContainer
                    >
                        <ProductTitleSpan>
                            {beforeAndAfterData[0].title}
                        </ProductTitleSpan>
                        <TitleSpan style={{ marginLeft: '4px' }}>
                            {beforeAndAfterData[0].text}
                        </TitleSpan>
                    </BeforeAfterTitleContainer>
                    <BeforeAfterImages>
                        <BeforeAfterImage>
                            <img src={beforeAndAfterData[0].beforeImage} />
                        </BeforeAfterImage>
                        <BeforeAfterImage>
                            <img src={beforeAndAfterData[0].afterImage} />
                        </BeforeAfterImage>
                    </BeforeAfterImages>

                    <BAContainer>
                        <BABoldSubtitle>
                            {beforeAndAfterData[0].subtitleBold}
                        </BABoldSubtitle>
                        <BASubtitle>
                            {beforeAndAfterData[0].subtitle}
                        </BASubtitle>
                    </BAContainer>
                </Spacing>
            )} */}

            {educationVideoTitle && educationVideoImage && educationVideos && (
                <Spacing
                    style={{
                        maxWidth: '740px',
                        margin: 'auto',
                        textAlign: 'center',
                    }}
                >
                    <ProductTitleSpan>{educationVideoTitle}</ProductTitleSpan>
                    <div
                        style={{
                            justifyContent: 'center',
                            display: 'flex',
                            marginTop: 16,
                        }}
                    >
                        <a
                            href={`/video/${educationVideos[0].slug}`}
                            style={{ display: 'flex' }}
                        >
                            {/* <ButtonPlay removeCircle> */}
                            <VideoImage>
                                <img
                                    src={
                                        educationVideoImage?.localFile
                                            ?.publicURL
                                    }
                                />
                            </VideoImage>
                            {/* </ButtonPlay> */}
                        </a>
                    </div>
                    {videoCtaText && videoYoutubeUrl && (
                        <Fragment>
                            <StLinkMore
                                rounded
                                invertTextColor
                                center
                                topMargin
                                bottomMargin
                                morePadding
                                hoverColor={blue}
                                onetwoColor
                                responsiveFont
                            >
                                <CustomLink
                                    target={'_blank'}
                                    style={{ letterSpacing: '0.659px' }}
                                    href={`/video/${educationVideos[0].slug}`}
                                >
                                    {videoCtaText}
                                </CustomLink>
                            </StLinkMore>
                        </Fragment>
                    )}
                </Spacing>
            )}

            {ultraAshProducts && ultraAshProductsTitle && (
                <Spacing
                    style={{
                        maxWidth: '740px',
                        margin: 'auto',
                        textAlign: 'center',
                    }}
                >
                    <ProductTitleSpan>{ultraAshProductsTitle}</ProductTitleSpan>
                    {ultraAshProducts?.map((p, index) => {
                        return (
                            <DesktopProductRow key={p.title}>
                                {index % 2 === 0 && (
                                    <ProductImageContainer>
                                        <ItemImageWrapper>
                                            <DesktopImage src={p.image} />
                                            <Image src={p.hoverImage} />
                                        </ItemImageWrapper>
                                    </ProductImageContainer>
                                )}

                                <ProductInfoContainer>
                                    <ProductInfoTitle>
                                        {p.title}
                                    </ProductInfoTitle>
                                    <ProductInfoText>{p.text}</ProductInfoText>
                                    <StLinkMore
                                        rounded
                                        invertTextColor
                                        center
                                        topMargin
                                        bottomMargin
                                        morePadding
                                        hoverColor={blue}
                                        onetwoColor
                                        responsiveFont
                                    >
                                        <CustomLink
                                            target={'_blank'}
                                            style={{ letterSpacing: '0.659px' }}
                                            href={`${p.ctaLink}`}
                                        >
                                            {p.ctaText}
                                        </CustomLink>
                                    </StLinkMore>
                                </ProductInfoContainer>

                                {index % 2 === 1 && (
                                    <ProductImageContainer>
                                        <ItemImageWrapper>
                                            <DesktopImage src={p.image} />
                                            <Image src={p.hoverImage} />
                                        </ItemImageWrapper>
                                    </ProductImageContainer>
                                )}
                            </DesktopProductRow>
                        )
                    })}
                    {ultraAshProducts && (
                        <ProductCarousel products={ultraAshProducts} />
                    )}
                </Spacing>
            )}

            {shopTitle && shopProducts && (
                <MobileOnly>
                    <Spacing style={{ maxWidth: "740px", margin: "auto", textAlign: "center" }}>
                        <ProductTitleSpan>
                            {shopTitle}
                        </ProductTitleSpan>
                        <ProductCarousel showDesktop products={shopProducts} />
                    </Spacing>
                </MobileOnly>
            )}

            {shopTitle && shopProducts && (
                <DesktopOnly>
                    <Spacing style={{ margin: 'auto', textAlign: 'center' }}>
                        <ProductTitleSpan>{shopTitle}</ProductTitleSpan>
                        <ScrollingProductsRow
                            title={shopTitle}
                            products={page.stylistFavoritesProducts}
                            shopifyProducts={allShopifyProducts}
                            allGoogleSheetProductsRow={allGoogleSheetProductsRow}
                            magicMousse={true}
                            mobileHorizontal={true}
                        />
                    </Spacing>
                </DesktopOnly>
            )}

            {beforeAndAfterData?.length > 1 && (
                <Spacing removeSpaceTop style={{ marginTop: '80px', margin: 'auto', backgroundColor: '#F5F5F5' }}>
                    <BAContainer2>
                        <BeforeAfterTitleContainer left
                        >
                            <ProductTitleSpan>
                                {beforeAndAfterData[1].title}
                            </ProductTitleSpan>
                        </BeforeAfterTitleContainer>
                        <BeforeAfterImages>
                            <BeforeAfterImage>
                                <img src={beforeAndAfterData[1].beforeImage} />
                            </BeforeAfterImage>
                            <BeforeAfterImage>
                                <img src={beforeAndAfterData[1].afterImage} />
                            </BeforeAfterImage>
                        </BeforeAfterImages>

                        <BAContainer left>
                            <BASubContainer left>
                                <BABoldSubtitle>
                                    {beforeAndAfterData[1].text1heading}
                                </BABoldSubtitle>
                                <BASubtitle left>
                                    {beforeAndAfterData[1].text1content}
                                </BASubtitle>
                            </BASubContainer>
                            <BASubContainer left>
                                <BABoldSubtitle>
                                    {beforeAndAfterData[1].text2heading}
                                </BABoldSubtitle>
                                <BASubtitle left>
                                    {beforeAndAfterData[1].text2content}
                                </BASubtitle>
                            </BASubContainer>
                            <BASubContainer left style={{ marginBottom: '0px' }}>
                                <StLinkMore
                                    rounded
                                    invertTextColor
                                    center
                                    morePadding
                                    hoverColor={blue}
                                    onetwoColor
                                    responsiveFont>
                                    <CustomLink
                                        target={'_blank'}
                                        style={{ letterSpacing: '0.659px' }}
                                        href={`${beforeAndAfterData[1].ctaLink}`}>
                                        {beforeAndAfterData[1].ctaText}
                                    </CustomLink>
                                </StLinkMore>
                            </BASubContainer>
                        </BAContainer>
                    </BAContainer2>
                </Spacing>
            )}

        </Fragment>
    )
}

export default injectIntl(UALanding)
